<template>
  <div class="grid grid-cols-2 xl:grid-cols-6 gap-5">
    <div class="col-span-1 border border-gray-200 rounded-md">
      <div class="px-2 py-3 bg-domLight">Total Credits</div>
      <div class="px-2 py-3 text-right text-3xl">${{ truncateNumber(dashboard.total_credits) }}</div>
    </div>
    <div class="col-span-1 border border-gray-200 rounded-md">
      <div class="px-2 py-3 bg-domLight">Last Payment</div>
      <div class="px-2 py-3 text-right text-3xl">${{ truncateNumber(dashboard.last_payment) }}</div>
    </div>
  </div>
</template>

<script>
import F from "../../helper/function";
export default {
  name: "Dashboard",
  data() {
    return {
      dashboard: null,
      pageLoading: false,
    };
  },
  created() {
    this.pageLoading = true;
    this.$api.get("admin/dashboard").then((response) => {
      this.dashboard = response.data;
      this.pageLoading = false;
    });
  },
  methods: {
    truncateNumber(value) {
      return F.truncateNumber(value, 2);
    }
  },
};
</script>